import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import BackIcon from "../utils/icons/Back Icon.svg";
import HomeIcon from "../utils/icons/Home Icon.svg";
import "../css/markdown.css";

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        texts {
          allCollectionsText
          articlesInCollectionZeroText
          articlesInCollectionOneText
          articlesInCollectionTwoText
          articlesInCollectionMultipleText
        }
      }
    }

    allAdminYaml {
      edges {
        node {
          collections {
            name
            description
            id
            slug
          }
        }
      }
    }

    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(format: HTML, pruneLength: 1000)
          parent {
            id
            ... on File {
              name
              sourceInstanceName
            }
          }
          frontmatter {
            title
            description
            date(formatString: "DD.MM.yyyy")
          }
          html
          rawMarkdownBody
        }
      }
    }
  }
`;

const article = ({ data, pageContext }) => {
  const { articleId } = pageContext;
  const articles = data.allMarkdownRemark.edges;
  const filtered_article = articles.filter(
    (item) => item.node.id === articleId
  );
  const article = filtered_article[0].node;
  const collection = data.allAdminYaml.edges[0].node.collections.filter(
    (item) => item.id === article.parent.sourceInstanceName
  );

  return (
    <Layout>
      <div
        id="article-div"
        className="container md:max-w-4xl sm:max-w-sm md:px-8 lg:px-0 max-w-xs xs:px-4 sm:px-0 mx-auto mt-8"
      >
        <div className="flex flex-row sm:items-start sm:justify-start  items-center">
          <Link to="/">
            <img
              className="bg-light-blue p-2 rounded-full mr-2 "
              src={HomeIcon}
              alt="Home"
            />
          </Link>
          <Link to={`/collections/${article.parent.sourceInstanceName}`}>
            <div className="bg-light-blue rounded-custom3 text-white px-4 py-2 sm:mr-2 sm:text-sm text-xs font-normal capitalize">
              {` ${collection[0].name}`}
            </div>
          </Link>
          <div className="bg-btn-blue xs:hidden mobile:hidden rounded-custom3 text-white px-4 py-2 sm:text-sm text-xs font-normal capitalize">
            {` ${article.frontmatter.title}`}
          </div>
        </div>

        {/* <p className=" py-0 ">
          <Link to="/">
            <span className="text-sm font-normal">
              {`${data.site.siteMetadata.texts.allCollectionsText} `}
            </span>
          </Link>
          <span className="text-sm font-normal">&rsaquo;</span>
          <Link to={`/collections/${article.parent.sourceInstanceName}`}>
            <span className="text-sm font-normal capitalize">
              {` ${article.parent.sourceInstanceName} `}
            </span>
          </Link>
          <span className="text-sm font-normal">&rsaquo;</span>
          <span className="text-sm font-normal capitalize">
            {` ${article.frontmatter.title}`}
          </span>
        </p> */}

        <div className="z-10 rounded bg-white border border-color-border mx-auto mt-8 p-8">
          <div className="flex flex-row items-start ">
            <div className="sm:w-1/12 w-1/6">
              <Link to={`/collections/${article.parent.sourceInstanceName}`}>
                <img
                  className="filter-blue1 mt-4"
                  src={BackIcon}
                  alt="BackLogo"
                />
              </Link>
            </div>
            <div className="sm:w-11/12 w-5/6">
              <h1 className="mt-2 sm:text-hxl text-base text-text-violet font-semibold capitalize">
                {article.frontmatter.title}
              </h1>
              <p className="text-xs text-light-blue font-thin  leading-normal tracking-wide">
                Last updated on {article.frontmatter.date}
              </p>
            </div>
          </div>
          <div className="markdown-body mt-12  sm:text-sm text-xs   font-normal text-text-blue">
            <div
              dangerouslySetInnerHTML={{
                __html: article.html,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default article;
